import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SupabaseService } from './services/supabase.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title: 'Vidget App'
  showSidenav: boolean = true;
  isActiveComponentSubnavConfig: boolean = false;

  constructor(private router: Router, private supabaseService: SupabaseService) { }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const url = event.urlAfterRedirects;
        this.showSidenav = !(url.includes('/login') || url.includes('/register'));
        // Define isActiveComponentSubnavConfig baseado na presença de um slug específico na URL
        this.isActiveComponentSubnavConfig = url.includes('/subnav-configuration'); // Adapte para a URL correta
      }
    });

    const currentUser = this.supabaseService.getCurrentUser();
    currentUser.then(user => {
      console.log('Current user:', user);
    }).catch(error => {
      console.error('Error fetching current user:', error);
    });
  }
}
