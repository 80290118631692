<div class="container-fluid p-0" *ngIf="!isMobile">
        <div class="row">
                <!-- Side Nav -->
                <div class="side-nav col-md-12 p-0" id="nav-container">
                        <a class="navbar-brand p-5 text-header" style="border: none !important">
                                <img src="../../../assets/public/logo-vidget.svg" alt="">
                        </a>
                        <div class="d-flex flex-column align-items-start ml-2 side-nav">
                                <a routerLink="/dashboard" routerLinkActive="active" (click)="onNavClick('/dashboard')"
                                        class="mt-5 mb-4 text-header" id="dashboard">
                                        <img src="../../../assets/public/i-dash.svg" alt=""> Dashboard
                                </a>
                                <a routerLink="/my-videos" routerLinkActive="active" (click)="onNavClick('/my-videos')"
                                        class="mb-4 text-header" id="myVideos">
                                        <img src="../../../assets/public/i-videos.svg" alt=""> Galeria
                                </a>
                                <a routerLink="/vidgets" routerLinkActive="active" (click)="onNavClick('/vidgets')"
                                        class="mb-4 text-header" id="myVidgets">
                                        <img src="../../../assets/public/i-videos.svg" alt=""> Meus Vidgets
                                </a>
                                <a routerLink="/settings" routerLinkActive="active" (click)="onNavClick('/settings')"
                                        class="mb-4 text-header" id="settings">
                                        <img src="../../../assets/public/i-settings.svg" alt=""> Configurações
                                </a>
                                <div *ngIf="loading" class="spinner">                                        
                                        <img src="../../../assets/public/spinner.gif" alt="Carregando..." style="width: 25%">
                                        <span>Carregando...</span>
                                </div>
                                <div *ngIf="!loading">
                                        <a class="mt-0 text-header" id="profile" style="border: none !important">
                                                <img id="photoNav"
                                                        style="max-width:4vw; max-height:3vw; border-radius: 50%; border: 2px solid #000; margin-right: 0;"
                                                        [src]="selectedFileUrl || '../../../assets/public/photo-profile-1.svg'"
                                                        alt="">
                                                <div (click)="toggleLogoutDropdown()" class="name-toggle">
                                                        <span class="user-full-name">{{name}}</span>
                                                        <span class="arrow-down"
                                                                [ngClass]="{'arrow-up': showLogoutDropdown}">▼</span>
                                                </div>
                                        </a>
                                </div>
                        </div>

                        <div id="logout-dropdown" *ngIf="showLogoutDropdown">
                                <a id="logoutLink" (click)="signOut(); closeDropdown()"><img
                                                src="../../../assets/public/i-logout.svg" alt="">Logout</a>

                                <a id="profileLink" routerLink="/settings" (click)="closeDropdown()"><img
                                                src="../../../assets/public/i-profile.svg" alt="">Perfil</a>
                        </div>

                </div>
                <div class="col-md-10 main">
                        <div class="content-flex d-flex" id="dynamicElements"></div>
                </div>
        </div>
</div>

<ng-container *ngIf="isMobile">
        <div class="mobile-sidenav primary-bg-color">
                <img class="logo" src="./../../../assets/public/logo-vidget-2.svg" alt="Logo">

                <button class="menu-icon" (click)="toggleSidenav()">
                        <img [src]="isMenuOpen ? '../../../assets/public/i-menu-close.svg' : '../../../assets/public/i-menu-open.svg'"
                                alt="Toggle Menu">
                </button>
        </div>
        <div class="mobile-nav-links" *ngIf="isMenuOpen">
                <a routerLink="/dashboard" routerLinkActive="active" (click)="onNavClick('/dashboard')"
                        class="links-mobile">
                        <img src="../../../assets/public/i-dash.svg" alt=""> Dashboard
                </a>
                <a routerLink="/my-videos" routerLinkActive="active" (click)="onNavClick('/my-videos')"
                        class="links-mobile">
                        <img src="../../../assets/public/i-videos.svg" alt=""> Galeria
                </a>
                <a routerLink="/vidgets" routerLinkActive="active" (click)="onNavClick('/vidgets')"
                        class="links-mobile">
                        <img src="../../../assets/public/i-videos.svg" alt=""> Meus Vidgets
                </a>
                <a routerLink="/settings" routerLinkActive="active" (click)="onNavClick('/settings')"
                        class="links-mobile">
                        <img src="../../../assets/public/i-settings.svg" alt=""> Configurações
                </a>
                <a routerLink="/settings" routerLinkActive="active" (click)="onNavClick('/settings')"
                        class="links-mobile">
                        <img id="photoNav"
                                style="max-width:4vw; max-height:3vw; border-radius: 50%; border: 2px solid #000; margin-right: 0;"
                                [src]="selectedFileUrl || '../../../assets/public/photo-profile-1.svg'" alt="">
                        <span class="user-full-name">{{name}}</span>
                </a>
        </div>
</ng-container>