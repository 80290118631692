import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { SupabaseService } from './supabase.service';
import { User } from '../models/user';
import { SupabaseClient } from '@supabase/supabase-js';
import { Observable } from 'rxjs';
import { ProfileUpdateService } from './ProfileUpdateService';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private supabase: SupabaseClient;
  private backendUrl = environment.backendUrl;

  constructor(private supabaseService: SupabaseService, private http: HttpClient, private profileUpdateService: ProfileUpdateService) {
    this.supabase = this.supabaseService.getClient();
  }

  async getUser(): Promise<User | null> {
    const { data, error } = await this.supabase.auth.getUser();
    if (error) {
      console.error('Error fetching user:', error);
      return null;
    }

    const user = data.user as unknown as User;
    return user;
  }

  async updateUserMetadata(userId: string, isGtmIntegrated: boolean): Promise<void> {
    try {
      const { data: user, error: fetchError } = await this.supabase
        .from('vidget::profile')
        .select('gtm_status')
        .eq('user_id', userId)
        .single();

      if (fetchError) {
        throw new Error(`Error fetching user metadata: ${fetchError.message}`);
      }


      // Atualiza o raw_user_meta_data na tabela
      const { error } = await this.supabase
        .from('vidget::profile')
        .update({ gtm_status: isGtmIntegrated })
        .eq('user_id', userId); // Presumindo que o id é o identificador do usuário

      if (error) {
        throw new Error(`Error updating user metadata: ${error.message}`);
      }

      console.log('User metadata updated successfully.');
    } catch (error) {
      console.error('Failed to update user metadata:', error);
    }
  }



  async signIn(email: string, password: string): Promise<boolean> {
    try {
      const { data, error } = await this.supabase.auth.signInWithPassword({ email, password });
      if (error) {
        console.error('Error during sign in:', error);
        return false;
      }
      console.log('Sign in response:', data);
      return true;
    } catch (error) {
      console.error('Error during sign in:', error);
      return false;
    }
  }

  async signUp(email: string, password: string, user_metadata: any): Promise<boolean> {
    try {
      console.log('UserService: Attempting to sign up with email:', email);
      const response = await this.http.post(`${this.backendUrl}/auth/signup`, { email, password, user_metadata }).toPromise();
      return !!response; // Retorna true se a resposta for bem-sucedida
    } catch (error) {
      console.error('Error during sign up:', error);
      return false; // Retorna false se ocorrer um erro
    }
  }

  async logOut(access_token: string): Promise<boolean> {
    try {
      const response = await this.http.post(`${this.backendUrl}/auth/logout`, { access_token }).toPromise();
      return !!response; // Retorna true se a resposta for bem-sucedida
    } catch (error) {
      console.error('Error during log out:', error);
      return false; // Retorna false se ocorrer um erro
    }
  }

  getCurrentAccessToken(): string | null {
    // Implementação depende de onde e como o token é armazenado
    return localStorage.getItem('access_token');
  }


  async resetPassword(email: string) {
    const { error } = await this.supabaseService.resetPassword(email);
    return { error };
  }

  async updatePassword(newPassword: string) {
    const { error } = await this.supabaseService.updatePassword(newPassword);
    return { error };
  }

  async getUserPlan(userId: string): Promise<string | null> {
    const { data, error } = await this.supabase.auth.admin.getUserById(userId);
    if (error) {
      console.error('Error fetching user plan:', error);
      return null;
    }

    // Convertendo explicitamente para o tipo User definido no seu projeto
    const user = data.user as unknown as User;

    return user.user_metadata.planType;
  }

  async updateUserProfile(profileData: any): Promise<boolean> {
    const user = await this.getUser();
    console.log('Usuário atual:', user);

    if (!user) {
      console.error('Nenhum usuário logado');
      return false;
    }

    // Verificar se o registro existe
    const { data: existingProfile, error: fetchError } = await this.supabase
      .from('vidget::profile')
      .select('*')
      .eq('user_id', user.id)
      .single();

    if (fetchError && fetchError.code !== 'PGRST116') {
      console.error('Erro ao verificar o perfil existente:', fetchError);
      return false;
    }

    if (!existingProfile) {
      // Registro não existe, realizar o insert
      console.log('Nenhum registro encontrado para o user_id:', user.id);
      console.log('Inserindo novo registro no banco de dados...');

      const { data: insertData, error: insertError } = await this.supabase
        .from('vidget::profile')
        .insert([{ ...profileData, user_id: user.id }]);

      if (insertError) {
        console.error('Erro ao inserir novo perfil no banco de dados:', insertError);
        return false;
      }

      console.log('Novo perfil inserido com sucesso:', insertData);
      return true;
    } else {
      // Registro existe, realizar o update
      console.log('Registro existente encontrado:', existingProfile);

      const { data: updateData, error: updateError } = await this.supabase
        .from('vidget::profile')
        .update(profileData)
        .eq('user_id', user.id);

      if (updateError) {
        console.error('Erro ao atualizar o perfil no banco de dados:', updateError);
        return false;
      }

      console.log('Perfil atualizado com sucesso no banco de dados:', updateData);
      return true;
    }
  }


  async checkVidgetLimit(userId: string): Promise<boolean> {
    const user = await this.getUser();
    if (!user) return false;

    const planType = user.user_metadata.planType;

    const { data: vidgets, error } = await this.supabase
      .from('vidget::video_sets')
      .select('*')
      .eq('user_id', userId);

    if (error) {
      console.error('Error fetching vidgets:', error);
      return false;
    }

    let maxVidgets;
    if (planType === 'free') {
      maxVidgets = 3;
    } else if (planType === 'beginner') {
      maxVidgets = 25;
    } else if (planType === 'expert') {
      maxVidgets = 150;
    } else {
      maxVidgets = Infinity; // Sem limite para outros planos
    }

    return vidgets.length < maxVidgets;
  }

  async checkActiveVideosLimit(userId: string): Promise<boolean> {
    const user = await this.getUser();
    if (!user) return false;

    const planType = user.user_metadata.planType;

    const { data: videos, error } = await this.supabase
      .from('vidget::videos')
      .select('*')
      .eq('metadata->>userId', userId)
      .eq('status_video', 'active');

    if (error) {
      console.error('Error fetching active videos:', error);
      return false;
    }

    let maxViews;
    if (planType === 'free') {
      maxViews = 500;
    } else if (planType === 'beginner') {
      maxViews = 5000;
    } else if (planType === 'expert') {
      maxViews = 15000;
    } else {
      maxViews = Infinity; // Sem limite para outros planos
    }

    const totalViews = videos.reduce((sum, video) => sum + (video.metadata.views || 0), 0);

    return totalViews < maxViews;
  }

  async uploadProfileImage(file: File): Promise<boolean> {
    try {
      const user = await this.getUser();
      if (!user) {
        console.error('No user logged in');
        return false;
      }

      const fileName = `${user.id}/${user.id}_profile_${Date.now()}.${file.name.split('.').pop()}`;
      const { data: uploadData, error: uploadError } = await this.supabase.storage
        .from('vidget::profile')
        .upload(fileName, file);

      if (uploadError) {
        console.error('Error uploading file:', uploadError);
        return false;
      }

      const imageUrl = `https://phxkpzehxsbteunlmdoq.supabase.co/storage/v1/object/public/vidget::profile/${fileName}`;
      await this.updateUserProfile({ profile_img: imageUrl });
      this.profileUpdateService.updateProfileImageUrl(imageUrl);

      return true;
    } catch (error) {
      console.error('Error uploading profile image:', error);
      return false;
    }
  }

  async getActiveVideosData(userId: string): Promise<{ totalViews: number, videos: any[] }> {
    const { data: videos, error } = await this.supabase
      .from('vidget::videos')
      .select('*')
      .eq('metadata->>userId', userId)
      .eq('status_video', 'active');

    if (error) {
      console.error('Error fetching active videos:', error);
      return { totalViews: 0, videos: [] };
    }

    const totalViews = videos.reduce((sum, video) => sum + (video.metadata.views || 0), 0);
    return { totalViews, videos };
  }


  async removeProfileImage(): Promise<boolean> {
    try {
      await this.updateUserProfile({ profile_img: null });
      return true;
    } catch (error) {
      console.error('Error removing profile image:', error);
      return false;
    }
  }
  async getImg(): Promise<string | null> {
    try {
      const user = await this.getUser();
      if (!user) {
        console.error('No user logged in');
        return null;
      }

      const { data, error } = await this.supabase
        .from('vidget::profile')
        .select('profile_img')
        .eq('user_id', user.id)
        .single();

      if (error) {
        console.error('Error fetching user profile image:', error);
        return null;
      }

      return data?.profile_img ?? null;
    } catch (error) {
      console.error('Error fetching user profile image:', error);
      return null;
    }
  }

  async getName(): Promise<string | null> {
    try {
      const user = await this.getUser();
      if (!user) {
        console.error('No user logged in');
        return null;
      }

      const { data, error } = await this.supabase
        .from('vidget::profile')
        .select('name')
        .eq('user_id', user.id)
        .single();

      if (error) {
        console.error('Error fetching user profile image:', error);
        return null;
      }

      return data?.name ?? null;
    } catch (error) {
      console.error('Error fetching user profile image:', error);
      return null;
    }
  }

  async getProfile(): Promise<{ name: string, role: string } | null> {
    try {
      const user = await this.getUser();
      if (!user) {
        console.error('No user logged in');
        return null;
      }

      const { data, error } = await this.supabase
        .from('vidget::profile')
        .select('name, role')
        .eq('user_id', user.id)
        .single();

      if (error) {
        console.error('Error fetching user profile:', error);
        return null;
      }

      return data ? { name: data.name, role: data.role } : null;
    } catch (error) {
      console.error('Error fetching user profile:', error);
      return null;
    }
  }
  async getUserById(userId: string): Promise<any> {
    // Consulta ao Supabase para obter o usuário pelo ID
    const { data: user, error } = await this.supabase
      .from('vidget::profile') // Certifique-se de que a tabela está correta
      .select('*')
      .eq('user_id', userId)
      .single();

    if (error) {
      console.error('Erro ao buscar o usuário:', error);
      return null;
    }

    return user;
  }


}
