import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor() {}

  showError(title: string, text: string) {
    Swal.fire({
      icon: 'error',
      title: title,
      text: text
    });
  }

  showSuccess(title: string, text: string) {
    Swal.fire({
      icon: 'success',
      title: title,
      text: text
    });
  }

  showSuccessAndWait(title: string, text: string): Promise<void> {
    // Nova função que espera pelo clique no botão OK
    return Swal.fire({
      icon: 'success',
      title: title,
      text: text,
      confirmButtonText: 'OK'
    }).then((result) => {
      if (result.isConfirmed) {
        return;
      }
    });
  }

  showInfo(title: string, text: string) {
    Swal.fire({
      icon: 'info',
      title: title,
      text: text
    });
  }

  showWarning(title: string, text: string): Promise<any> {  // Alterando para retornar uma Promise
    return Swal.fire({
      icon: 'warning',
      title: title,
      text: text,
      showCancelButton: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    });
  }
}
