import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ProfileUpdateService {
    private profileImageUrlSubject = new BehaviorSubject<string | null>(null);
    profileImageUrl$ = this.profileImageUrlSubject.asObservable();
    private profileUpdatedSubject = new BehaviorSubject<boolean>(false);

    profileUpdated$ = this.profileUpdatedSubject.asObservable();
  
    updateProfile() {
      this.profileUpdatedSubject.next(true);
    }
  
    updateProfileImageUrl(url: string) {
        this.profileImageUrlSubject.next(url);
    }
}
