// layout.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
  private sidenavExpandedSource = new BehaviorSubject<boolean>(false);
  sidenavExpanded$ = this.sidenavExpandedSource.asObservable();

  toggleSidenav() {
    this.sidenavExpandedSource.next(!this.sidenavExpandedSource.value);
  }

  isSidenavExpanded(): boolean {
    return this.sidenavExpandedSource.value;
  }
}
